#root {
  height: 100%;
}

html, body {
  height: 100%;
}

body {
  height: inherit;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('https://use.fontawesome.com/releases/v5.7.2/css/all.css') format('svg');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  background: url(/static/media/trianglify-bg.a0c5249c.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

.Panamericano {
  text-align: center;
  max-height: 500px;
  max-width: 100%;
}

.banner {
  height: auto;
  width: 90%;
}

.calltoaction {
	margin-top: 40px;
	display: inline-block;
}

.calltoaction a:hover {
	background: linear-gradient(to bottom right, #e8bf27, #e8cf14);
	box-shadow:none !important;
  color: #fff;
  font-weight: 500;
}

.calltoaction a:active {
	border:none;
}

.calltoaction a:after {
	font-family:FontAwesome,icon;
	content:'\F03D';
	padding-left:7px;
	font-size:24px;
	opacity:0.99;
	font-style: normal;
	font-weight: normal;
}

.calltoaction a {
	display: block;
	height: 50px;
	width: 300px;
	border-radius:10px;
	cursor:pointer;
	font-size: 24px;
	padding: 15px 0px 0px 0px;
	text-align:center;
	text-decoration:none;
	border:3px solid #dcba3a;
	background:linear-gradient(to bottom right, #e8bf27, #f4cf40);
	color:#604e0b;
	text-transform:lowercase;
}

.calltoaction a:hover {
    animation-play-state: paused;
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    -o-animation-play-state: paused;
    -ms-animation-play-state: paused;
}

.calltoaction p {
	background: rgba(0,0,0,0.4);
	display: block;
	height: 40px;
	width: 280px;
	margin: -50px 0 0 10px;
	border-radius:5px;
	text-align: center;
	font: 12px/45px 'Muli', Verdana, sans-serif;
	color: #fff;
	position: absolute;
	z-index: -1;
	transition: margin 0.5s ease;
}

.calltoaction:hover .bottom {margin: -10px 0 0 10px}
.calltoaction:hover .top {margin: -80px 0 0 10px;line-height: 35px}
.calltoaction:active .bottom {margin: -10px 0 0 10px}
.calltoaction:active .top {margin: -50px 0 0 10px}

/* social icon container background on hold, not aligning perfect */
hold .social li a {background: rgba(0,0,0,0.5)}

/* inline adjustments for social icons within sections */
.accounts {
  margin-top: 40px;
}

.social {
	height:30px;
	display:inline-block;
	margin:0 auto;
}

.social li {
	float: left;
  list-style-type: none;
}
.social li a {
	transition: all 250ms ease-out;
    display: block;
    height: 20px;
    margin: 0 2px 0 0;
    padding: 8px 6px;
    width: 20px;
    text-decoration:none;
    font-size:0;
    border-radius:6px;
}

.social li a:hover {
    margin: -4px 2px 0 0;
    padding: 10px 6px 10px;
    height:14px;
}

.social li a:before {
	font-family:'FontAwesome',icon;
	display:block;
	text-align:center;
	font-size:18px;
	color:#fff;
	text-indent:0;
	text-align:center;
	width:30px;
	margin-left:-3px;
	opacity:0.99;
	font-style: normal;
	font-weight: normal;
}

.social .twitter a:before { content:'\F099' }
.social li.twitter a:hover {background: #33CCFF}

.social .facebook a:before { content:'\F300' }
.social li.facebook a:hover {background: #3B5998}

.social .vimeo a:before { content:'\F30F' }
.social li.vimeo a:hover {background: #86B32D}

.social .github a:before { content:'\F09B' }
.social li.github a:hover {background: #4183c4}

.social .skype a:before { content:'\F30B' }
.social li.skype a:hover {background: #00b0f6}

.social .linkedin a:before { content:'\F0E1' }
.social li.linkedin a:hover {background: #4875B4}

.social .tumbler a:before { content:'\F311' }
.social li.tumbler a:hover {background: #2B4964}

.social .youtube a:before { content:'\F16A' }
.social li.youtube a:hover {background: #FF3333}

.social .dribbble a:before { content:'\F17D' }
.social li.dribbble a:hover {background: #ea4c89}

.social .instagram a:before { content:'\F16D' }
.social li.instagram a:hover {background: #517fa4}

.social .vine a:before { content:'\F1CA' }
.social li.vine a:hover {background: #00bf8f}

.social .snapchat a:before { content:'\F2AC' }
.social li.snapchat a:hover {background: #fffc00}

.social .twitch a:before { content: '\F1E8' }
.social li.twitch a:hover {background: #6441a5}

.social .mail a:before { content: '\F003' }
.social li.mail a:hover {background: #c71610 }

